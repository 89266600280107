// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGSKaig0oeaC_OHrKUJq0TxCMiUqLMKRM",
  authDomain: "fluttersnippet-tech.firebaseapp.com",
  projectId: "fluttersnippet-tech",
  storageBucket: "fluttersnippet-tech.appspot.com",
  messagingSenderId: "420162675953",
  appId: "1:420162675953:web:6806585bd9bdbe7ed0f550",
  measurementId: "G-5S02JBQRY3"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };