import React from "react";

export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto flex flex-col md:flex-row justify-between">
        <div className="mb-6 md:mb-0 ms-4">
          <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
          <p>
            Email:{" "}
            <a
              href="mailto:mondal.sabir.com97@gmail.com"
              className="text-blue-400"
            >
              mondal.sabir.com97@gmail.com
            </a>
          </p>
          <p>
            Phone: <span className="text-blue-400">(+91) 8671418378</span>
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4  ms-4">Follow Us</h3>
          <ul className="flex  ms-4">
            <li className="mr-4">
              <a href="http://www.google.com" target="_blank" rel="noreferrer" className="text-blue-400 hover:text-blue-300">
                Twitter
              </a>
            </li>
            <li className="mr-4">
              <a href="http://www.google.com" target="_blank" rel="noreferrer" className="text-blue-400 hover:text-blue-300">
                Facebook
              </a>
            </li>
            <li>
              <a href="http://www.google.com" target="_blank" rel="noreferrer" className="text-blue-400 hover:text-blue-300">
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
