import React from 'react'
import SnippetRowCard from '../../components/SnippetRowCard'

export default function SnippetRowCards({documents}) {
  return (
    <div className="flex-col">
    {documents.items.map((e, snippetIndex) => (
      <SnippetRowCard
        className="bg-gray-200 p-4"
        content={e}
        index={snippetIndex}
      />
    ))}
  </div>
  )
}
