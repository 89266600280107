import React from "react";

export default function About() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <h1 className="text-4xl font-bold mb-6">About Us</h1>
      <div className="max-w-4xl px-6">
        <p className="text-lg text-gray-700 mb-4">
          Welcome to our Flutter snippet website! We're dedicated to providing
          you with high-quality Flutter-related articles, tutorials, and
          snippets to help you become a better Flutter developer.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Our team consists of passionate Flutter developers who love sharing
          their knowledge and expertise with the community. Whether you're a
          beginner or an experienced developer, we have resources tailored to
          your needs.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          In addition to articles and tutorials, we also offer a collection of
          ready-to-use Flutter snippets that you can incorporate into your
          projects. These snippets cover a wide range of topics, from UI design
          to state management and beyond.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          We believe in the power of Flutter to create beautiful, fast, and
          cross-platform mobile applications, and we're excited to share our
          knowledge and experience with you.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          If you have any questions, feedback, or suggestions for topics you'd
          like us to cover, please don't hesitate to reach out to us at
          contact@yourwebsite.com. We'd love to hear from you!
        </p>
      </div>
    </div>
  );
}
