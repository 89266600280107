import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './router';
/**
 * 
 * @returns #007100
 * #81344f
 * #00a400
 * #003e00
 */
function App() {
  return (
    <div className="App">
    <RouterProvider router={router} />
    </div>
  );
}

export default App;
