import React from 'react'
import ContentCard from '../../components/ContentCard';

export default function SnippetCards({documents}) {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {documents.items.map((e, snippetIndex) => (
            <ContentCard
              className="bg-gray-200 p-4"
              content={e}
              index={snippetIndex}
            />
          ))}
        </div>
      );
}
