import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const snippet = {
  title: "Sample Article",
  sections: [
    {
      heading: "Section 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      heading: "Section 2",
      description:
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      heading: "Section 3",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ],
};

export default function SnippetDetails() {
  const { id } = useParams();
  const [snippetDetails, setSnippetDetails] = useState(null);

  useEffect(() => {
    setSnippetDetails(snippet);

    return () => {
      // Any cleanup code if needed
    };
  }, []);
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4 mx-4">{id}</h1>
      <div className="grid gap-6">
        {snippetDetails === null ? (
          <div></div>
        ) : (
          <div>
            {snippetDetails.sections.map((section, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-2">
                  {section.heading}
                </h2>
                <p className="text-gray-700">{section.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
