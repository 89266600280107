import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import SnippetDetails from "./pages/snippet_details/SnippetDetails";
import About from "./pages/about/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: ":id",
    element: <SnippetDetails />,
  },
  {
    path: "/account",
    element: <div>Account</div>,
  },{
    path: "/contact",
    element: <div>Contact</div>,
  },
]);

export default router;
