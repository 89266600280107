import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { onSnapshot, collection, query } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import SnippetCards from "./SnippetCards";
import Footer from "../../components/Footer";
import SnippetRowCards from "./SnippetRowCards";

const homeConfigCollectionRef = "homeConfig";

export default function HomePage() {
  const [configs, setConfigs] = useState(null);
  const fetchHomeConfig = async () => {
    const q = query(collection(db, homeConfigCollectionRef));
    onSnapshot(q, (querySnapshot) => {
      const configs = querySnapshot.docs.map((doc) => doc.data());
      console.log(configs);
      setConfigs(configs);
    });
  };
  useEffect(() => {
    fetchHomeConfig();

    return () => {
      // Any cleanup code if needed
    };
  }, []);
  return (
    <div>
      <NavBar />
      {configs === null ? (
        <div
          style={{
            height: "100vh",
          }}
        ></div>
      ) : (
        <div>
          {" "}
          {configs.map((e) => {
            if (e.type === "grid") {
              return (
                <div className="px-1">
                  <h2 className="text-3xl font-bold dark:text-white py-2">
                    {e.heading}
                  </h2>
                  <SnippetCards documents={e} />
                </div>
              );
            }
            return (
              <div className="px-1">
                <h2 className="text-3xl font-bold dark:text-white py-2">
                  {e.heading}
                </h2>
                <SnippetRowCards documents={e} />
              </div>
            );
          })}
        </div>
      )}
      <Footer />
    </div>
  );
}
