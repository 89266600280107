import React from "react";
// import { useNavigate } from "react-router-dom";

export default function SnippetRowCard({ content }) {
//   const navigate = useNavigate();
  return (
    <div className="flex gap-4  p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
      {content.imgUrl === null ? (
        <div></div>
      ) : (
        <img className=" max-w-32" src={content.imgUrl} alt={content.heading} />
      )}

      <div>
        {" "}
        <div className="px-2 py-4">
          <div className="font-bold text-xl mb-2">{content.heading}</div>
          <p className="text-gray-700 text-base">{content.desc}</p>
        </div>
        <div className="px-6 pt-4 pb-2">
          {content.tags.map((tag) => (
            <span
              key={tag}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
            >
              {tag}
            </span>
          ))}
        </div>{" "}
      </div>
    </div>
  );
}
